
export const  eventServerNameToEventMeta = {
    DriverDistraction: {
      displayName: "Driver Distraction",
    },
    DriverDrowsiness: {
      displayName: "Driver Drowsiness",
    },
    DriverAsleep: {
      displayName: "Driver Asleep",
    },
    PhoneUse: {
      displayName: "Phone Use",
       
    },
    DriverSmoking: {
      displayName: "Driver Smoking",
    },
    UnfastenedSeatbelt: {
      displayName: "Seatbelt",
    },
    ExternalEvent: {
      displayName: "External Event",
    },
    TamperingDetection: {
      displayName: "Tampering Detection",
    },
    FCW: {
      displayName: "Forward Collision Warning (FCW)",
    },
    HMW: {
      displayName: "Tailgating (HMW)",
    },
    LDW: {
      displayName: "Lane Departure Warning (LDW)",
    },
    PCW: {
      displayName: "Pedestrian Collision Warning (PCW)",
    },
    UFCW: {
      displayName: "Urban Forward Collision Warning (UFCW)",
    },
    IgnitionON: {
      displayName: "Ignition ON",
    },
    DriverIdentified: {
      displayName: "Driver Identified",
    },
    IgnitionOFF: {
      displayName: "Ignition Off",
    },
    MovementStarted: {
      displayName: "Movement Started",
    },
    MovementStopped: {
      displayName: "Movement Stopped",
    },
    UnidentifiedUnauthDriver: {
      displayName: "Unidentified unauthorized driver",
    },
    DriverDisappeared: {
      displayName: "Driver Disappeared",
    },
    SystemBootOK: {
      displayName: "System Boot OK",
    },
    SystemBootFailure: {
      displayName: "System Boot Failure",
    },
    SystemReset: {
      displayName: "System Reset",
    },
    EnteringSleepMode: {
      displayName: "Entering Sleep Mode",
    },
    SystemError: {
      displayName: "System Error",
    },
    SystemOK: {
      displayName: "System OK",
    },
    CalibrationCompleted: {
      displayName: "Calibration Completed",
    },
    "3DAccelerometerCalibration Completed": {
      displayName: "Accelerometer Calibration Completed",
    },
    DriverIDUpdated: {
      displayName: "Driver ID Updated",
    },
    GpsStatusUpdate: {
      displayName: "GPS Status Update",
    },
    CameraCalibrationFailed: {
      displayName: "Camera Calibration Failed",
    },
    FwUpdateStarted: {
      displayName: "FW Update Started",
    },
    FwUpdateCompleted: {
      displayName: "FW Update Completed",
    },
    FwUpdateFailed: {
      displayName: "FW Update Failed",
    },
    ApplicationError: {
      displayName: "Application Error",
    },
    CameraError: {
      displayName: "Camera Error",
    },
    Heartbeat: {
      displayName: "Heartbeat",
    },
    UnknownDriver: {
      displayName: "Unknown Driver",
    },
    MCULOG: {
      displayName: "MCU LOG",
    },
    OrientationSaved: {
      displayName: "Orientation Saved",
    },
    DriverChanged: {
      displayName: "Driver Changed",
    },
  } ;