import React, { useState, useEffect, useContext } from 'react';
import TableWithExtensions from '../components/TableWithExtensions';
import { MemoizedFilters } from '../components/Filters';
import CustomPagination from '../components/ui/CustomPagination';
import PageTitle from '../components/ui/PageTitle';
import { getDevices, removeOwnerFromDevice, getEventTypes } from '../service/fleetsenseApiClient';
import { MdSettingsRemote } from 'react-icons/md';
import { buildFiltersString } from '../utils/filtersUtil';
import { convertISODateStringToFormattedDateString } from '../utils/dateUtil';
import TableDataContext from '../context/tableDataContext';
import { map, isNumber, filter } from 'lodash';
import {eventServerNameToEventMeta} from "../utils/eventTypes"
const Devices = (props) => {

    const columns = [
        {
            column: "id",
            displayName: "Unit ID",
            type: "multiselect",
            valueType: "string",
            width: 7,
            filterWidth: 13,
            placeholder: "Unit ID",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        // {
        //     column: "driverID",
        //     displayName: "Driver ID",
        //     type: "input",
        //     valueType: "string",
        //     width: 10,
        //     placeholder: "Driver ID",
        //     filterable: true,
        //     filterOptions: [],
        //     sortable: true
        // },
        {
            column: "vehicleId",
            displayName: "Vehicle ID",
            type: "input",
            valueType: "string",
            width: 7,
            filterWidth: 12,
            placeholder: "Vehicle ID",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "deviceType",
            displayName: "Device Type",
            type: "input",
            valueType: "string",
            width: 6,
            filterWidth: 12,
            placeholder: "Device Type",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "time",
            displayName: "Last Connected",
            type: "date",
            valueType: "string",
            width: 9,
            filterWidth: 34,
            placeholder: "Last Connected",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "location",
            displayName: "Last Known Location",
            type: "dropdown",
            valueType: "string",
            width: 24,
            filterWidth: 24,
            placeholder: "Location",
            filterable: false,
            filterOptions: [],
            sortable: false
        },
        // {
        //     column: "headingAngle",
        //     displayName: "Heading Angle",
        //     type: "input",
        //     width: 4,
        //     placeholder: "Heading Angle",
        //     filterable: false,
        //     filterOptions: [],
        //     sortable: false
        // },
        {
            column: "extPower",
            displayName: "Ext. Power",
            type: "input",
            valueType: "number",
            width: 6,
            filterWidth: 6,
            placeholder: "Ext. Power",
            filterable: false,
            filterOptions: [],
            sortable: false
        },
        {
            column: "internalBatteryPower",
            displayName: "Int. Power",
            type: "input",
            valueType: "number",
            width: 6,
            filterWidth: 6,
            placeholder: "Int. Power",
            filterable: false,
            filterOptions: [],
            sortable: false
        },
        {
            column: "eventType",
            displayName: "Last Event Received",
            type: "multiselect",
            valueType: "string",
            width: 16,
            filterWidth: 15,
            placeholder: "Last event received",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "ownerEmail",
            displayName: "Account",
            type: "input",
            valueType: "string",
            width: 15,
            filterWidth: 13,
            placeholder: "Account",
            filterable: true,
            filterOptions: [],
            sortable: true
        }
    ];

    const rowsPerPage = 10;

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsData, setRowsData] = useState([]);
    const [currentFiltersString, setCurrentFiltersString] = useState("");
    const [currentSorts, setCurrentSorts] = useState("");
    const [currentSortOrder, setCurrentSortOrder] = useState("");
    const [currentSortsString, setCurrentSortsString] = useState("-id");
    const [rowIndexOfOwnerToRemove, setRowIndexOfOwnerToRemove] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [eventTypes, setEventTypes] = useState([]);
    const [unitIds, setUnitIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { setFetchCallback, setFetchCallbackParams } = useContext(TableDataContext);

    useEffect(() => {
        setIsLoading(true);
        unitIdsApiCall();
        eventTypesApiCall();
        setFetchCallback(() => exportDataApiCall);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getDevicesApiCall();
        setFetchCallbackParams([null, null, currentFiltersString, currentSortsString]);
    }, [currentPage, currentFiltersString, currentSortsString]);

    useEffect(() => {
        setCurrentPage(1);
    }, [currentFiltersString]);

    useEffect(() => {
        if (currentSorts) {
            setCurrentSortsString(`${currentSortOrder}${currentSorts}`);
        }
    }, [currentSorts, currentSortOrder]);

    useEffect(() => {
        if (isNumber(rowIndexOfOwnerToRemove)) {
            const rowData = rowsData[rowIndexOfOwnerToRemove];
            removeOwnerFromDeviceApiCall(rowData.id, rowData.ownerEmail);
        }
    }, [rowIndexOfOwnerToRemove]);

    const getDevicesApiCall = async () => {
        let res;

        try {
            res = await getDevices(currentPage, rowsPerPage, currentFiltersString, currentSortsString);
            const data = prepareData(res.data);

            setTotalRows(res.header.totalCount);
            setRowsData(data);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const unitIdsApiCall = async () => {
        const res = await getDevices();
        const data = res.data;
        const unitIds = data.map(d => d.id).sort();

        setUnitIds(unitIds);
    };

    const exportDataApiCall = async (page, pageSize, filters, sorts) => {
        const res = await getDevices(page, pageSize, filters, sorts);
        const data = res.data;
        const flatData = data.map(row => {
            const lastEventMessage = row.lastEventMessage ?? {};

            if (lastEventMessage.iOArrayStatus) {
                const convertedIOArrayStatus = {};
                lastEventMessage.iOArrayStatus.forEach(input => {
                    convertedIOArrayStatus[input.portId] = JSON.stringify(input).replace(/\{|\}|"/g, '').replaceAll(',', ' | ');
                });
                lastEventMessage.iOArrayStatus = convertedIOArrayStatus;
            }

            return { id: row.id, vehicleId: row.vehicleId, deviceType: row.deviceType, ...lastEventMessage, ownerEmail: row.ownerEmail };
        });

        return flatData;
    };

    const removeOwnerFromDeviceApiCall = async (deviceId, email) => {
        try {
            await removeOwnerFromDevice(deviceId, email);
            window.location.reload(false);
        }
        catch (e) {

        }
    };

    const eventTypesApiCall = async () => {
        setEventTypes(Object.keys(eventServerNameToEventMeta));
    };

    const prepareData = data => {
        return map(data, dataRow => {
            const lastEventMessage = dataRow.lastEventMessage;

            const lastEventData = lastEventMessage
                ? {
                    // driverID: lastEventMessage.driverID, 
                    time: convertISODateStringToFormattedDateString(lastEventMessage.eventDateTime),
                    location: lastEventMessage.lastKnownStreetAddress,
                    // headingAngle: lastEventMessage.headingAngle,
                    extPower: lastEventMessage.extPower,
                    internalBatteryPower: lastEventMessage.internalBatteryPower,
                    eventType: lastEventMessage.eventType
                }
                : {
                    // driverID: '',
                    time: '',
                    location: '',
                    // headingAngle: '',
                    extPower: '',
                    internalBatteryPower: '',
                    status: '',
                };

            return {
                id: dataRow.id,
                vehicleId: dataRow.vehicleId,
                deviceType: dataRow.deviceType,
                ...lastEventData,
                ownerEmail: dataRow.ownerEmail
            }
        });
    }

    const onGoToPageCallback = pageNum => {
        setCurrentPage(pageNum);
    }

    const onFiltersChangedCallback = filters => {
        setCurrentFiltersString(buildFiltersString(columns, filters));
    }

    const onSortByCallback = columnName => {
        setCurrentSorts(columnName);
    }

    const onSortOrderCallback = order => {
        setCurrentSortOrder(order === 'DESC' ? "-" : "");
    }

    const onRemoveOwnerCallback = rowIndex => {
        setRowIndexOfOwnerToRemove(rowIndex);
    }

    const populateEventTypesFilterOptions = columns => {
        filter(columns, c => c.column === 'eventType')[0].filterOptions = eventTypes;
        filter(columns, c => c.column === 'id')[0].filterOptions = unitIds;
        return columns;
    }

    return (
        <div className="devices-container" style={{ display: 'flex', flexDirection: 'column' }}>
            <PageTitle title={"DEVICES"} variant={"primary"} >
                <MdSettingsRemote />
            </PageTitle>
            <MemoizedFilters columns={populateEventTypesFilterOptions(columns)} onFiltersChangedCallback={onFiltersChangedCallback} />
            <TableWithExtensions
                columns={columns}
                rowsData={rowsData}
                onFiltersChangedCallback={onFiltersChangedCallback}
                onSortByCallback={onSortByCallback}
                onSortOrderCallback={onSortOrderCallback}
                onRemoveOwnerCallback={onRemoveOwnerCallback}
                withCheckbox={true} pageType={"devices"}
                ownerColumnIndex={columns.length - 1}
                initSortBy={currentSorts}
                initSortOrder={currentSorts ? (currentSortOrder ? 'DESC' : 'ACS') : ''}
                isLoading={isLoading} />
            {!isLoading && <CustomPagination total={totalRows} perPage={rowsPerPage} currentPage={currentPage} onGoToPageCallback={onGoToPageCallback} />}
        </div>
    );
}

export default Devices;
